
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {searchUserForOverride, overridenow}  from "@/api/override";
import { string } from "yup/lib/locale";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentUser, getCurrentUser } from '@/utils/index';
import { UserRole } from '@/utils/auth.roles';


export default defineComponent({
  name: "reorder",
  components: {
  },
  data() {
    return { 
      list : [],
      searchField : "fullname",
      searchKey : "",
      fullnameFirst : "",
      fullnameMiddle : "",
      fullnameLast : "",
      currentUserID : "",
    }
  },
  mounted(){
      
  },
  methods : {
    async searchUser(){
       var param = {
        "page" : 1,
        "searchField" : this.searchField,
        "searchKey" : this.searchKey,
        "fullnameF" : this.fullnameFirst,
        "fullnameM" : this.fullnameMiddle,
        "fullnameL" : this.fullnameLast
      };
      const response = await searchUserForOverride(param);
      this.list = response.data.pageList;
      console.log(response.data);
    },
    async overrideNow(cifid){
      var param = {
        "cifid" : cifid
      };
      const response = await overridenow(param);
        

        const user = {
            un: response.data.email,
            fname: response.data.fname,
            mname: response.data.mname,
            lname: response.data.lname,
            avatar: response.data.avatar,
            role: UserRole.User,
            usid: response.data.usid,
            email: response.data.email,
            address: response.data.address,
            bday: response.data.bday,
            mobile: response.data.mobile
        };
        setCurrentUser(user);

        localStorage.ec_current_usid = response.data.usid;
        this.currentUserID = localStorage.ec_current_usid;

        if (response.data.result == "SUCCESS") {
            localStorage.setItem('override_cifid', cifid);

            if(localStorage.getItem("pt") === 'ovi'){
               //this.$router.push('/dashboard/reminderspage');
               //window.location.assign('/dashboard/reminderspage');
               window.location.reload;
            } else {
              if(localStorage.getItem("pt") === 'ecp' || localStorage.getItem("pt") === 'edp'){
                //this.$router.push('/dashboardecp/reminderspage');
                //window.location.assign('/dashboardecp/reminderspage');
                window.location.reload;
              }
            }
            window.location.reload();
            
        } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
          }

      
    }
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("overrideTab") || "0");
      setCurrentPageTitle("Override Account");

    });
    
  }
});
